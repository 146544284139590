// Here you can add other styles
.dense-table {
  font-size: 0.675rem
}

.a4-size {
  width: 950px;
}

.cursor-pointer {
  cursor: pointer;
}

.contrast-title {
  color: #fff;
  background: #363537;
}

.warning-class {
  font-weight: 800;
  color: red;
}

.ag-header-cell {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

// .ag-cell {
//   padding: 0 5px !important;
// }

// .ag-cell-wrapper.ag-row-group[class*="ag-row-group-indent"],
// .ag-cell-wrapper.ag-row-group-leaf-indent[class*="ag-row-group-indent"] {
//   padding-left: 0;
// }

.ag-theme-cb .ag-row-level-0 {
  background-color: #5F6057;
  color: white;
  font-weight: 800;
  padding-left: 17px !important;
}

.ag-theme-cb .ag-row-level-1 {
  background-color: #808080;
  color: white;
  font-weight: 600;
  padding-left: 34px !important;
}

.ag-theme-cb .ag-row-level-2 {
  padding-left: 51px !important;
}

.ag-theme-cb .ag-row-level-3 {
  padding-left: 112px !important
}

.ag-theme-cb .ag-row-group-indent-0 {
  padding-left: 17px !important
}


.ag-theme-cb .ag-row-group-indent-1 {
  padding-left: 34px !important
}

.ag-theme-cb .ag-row-group-indent-2 {
  padding-left: 51px !important
}

.ag-theme-cb .ag-row-group-indent-3 {
  padding-left: 68px !important
}

.ag-theme-cb .ag-row-group-indent-4 {
  padding-left: 85px !important
}

.rag-red {
  background-color: lightcoral;
}

.rag-green {
  background-color: lightgreen;
}

.rag-amber {
  background-color: lightsalmon;
}

.glanceIcon {
  display: inline-block;
  text-align: center;
  width: 48px
}

.glanceIcon .disabled {
  color: lightgrey;
}

.c-headline {
  font-family: 'Graphik-Bold';
  font-size: 16px;
  // margin-bottom: 8px;
}

.c-bodyText {
  font-family: 'Graphik-Regular';
  font-size: 14px;
}

.c-sidebar .c-sidebar-nav-link:hover,
.c-sidebar .c-sidebar-nav-dropdown-toggle:hover {
  color: #fff;
  background: rgb(252, 175, 51) !important
}

.table .thead-light th {
  color: #fff;
  background: #363537;
  // background: rgba(255, 255, 255, 0.05);
}

.card-header {
  font-weight: 700;
}

.json-text-area {
  height: 200px !important;
  font-family: 'Courier New', Courier, monospace;
}

.modal-dialog-monaco {
  width: 80vw;
}

.monaco-border {
  border: 1px solid #ebedef;
}

.modal-xl-inside {
  max-width: 1100px
}

.c-wrapper {
  padding-left: var(--cui-sidebar-occupy-start, 0);
}

.c-sidebarlight {
  background-color: #FFFF;
}

.c-sidebarlightgray {
  background-color: #fafafa;
}

.c-sideBarLogoWidth {
  margin-left: 50px;
  width: 150px;
}

.container-xxl {
  max-width: none;
}

.c-btn {
  color: white;
}
.c-line {
  margin-left: 55px;
  border-left: 1px solid #e6e6dc;
  height: 50px;
}

.c-btn-ipsec-generate {
  width: 180px;
  color: white;
}

.form-group {
  margin-bottom: 1rem;
}

a {
  text-decoration: none;
}